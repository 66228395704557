import React, { useEffect, useState } from 'react';
import { checkout, config } from '@imtbl/sdk';
import { passportInstance } from "../context/PassportService";

const baseConfig = new config.ImmutableConfiguration({
  environment: config.Environment.SANDBOX,
});

const checkoutSDK = new checkout.Checkout({ baseConfig });

const ZkMarketplace = () => {
  const [widget, setWidget] = useState(undefined);
  const [provider, setProvider] = useState(null);
  const [walletAddress, setWalletAddress] = useState('');

  useEffect(() => {
    if (passportInstance) {
      passportInstance.connectEvm();
    }
  }, []);

  useEffect(() => {
    (async () => {
      const factory = await checkoutSDK.widgets({
        config: {
          language: 'en',
          theme: checkout.WidgetTheme.DARK,
          WALLET: {
            showNetworkMenu: false,
            showDisconnectButton: true,
          },
        },
      });

      const checkoutWidget = factory.create(checkout.WidgetType.IMMUTABLE_COMMERCE);
      setWidget(checkoutWidget);
    })();
  }, []);

  useEffect(() => {
    if (!widget) return;

    widget.mount('mount-point', {
      flow: checkout.CommerceFlowType.CONNECT,
    });

    widget.addListener(
      checkout.CommerceEventType.SUCCESS,
      async (payload) => {
        console.log('Payload: ', payload);
        const { type, data } = payload;
        console.log('We are connected by:', data.walletProviderName);

        if (type === checkout.CommerceSuccessEventType.CONNECT_SUCCESS) {
          setProvider(data.provider);

          // Retrieve wallet address from provider
          const accounts = await data.provider.request({ method: 'eth_accounts' });
          if (accounts && accounts.length > 0) {
            setWalletAddress(accounts[0]);
          }

          widget.unmount();
        }
      }
    );

    widget.addListener(
      checkout.CommerceEventType.FAILURE,
      (payload) => {
        const { type, data } = payload;
        if (type === checkout.CommerceFailureEventType.CONNECT_FAILED) {
          console.log('Failed to connect', data.reason);
        }
      }
    );

    widget.addListener(checkout.CommerceEventType.CLOSE, () => {
      widget.unmount();
    });

    return () => {
      widget.removeListener(checkout.CommerceEventType.SUCCESS);
      widget.removeListener(checkout.CommerceEventType.FAILURE);
      widget.removeListener(checkout.CommerceEventType.CLOSE);
    };
  }, [widget]);

  return (
    <div style={{ margin: 75 }}>
      <div id="mount-point" />
      {provider && walletAddress && (
        <div>
          <h2>Welcome to zkEVM Marketplace</h2>
          <p>Connected Wallet Address: {walletAddress}</p>
        </div>
      )}
    </div>
  );
};

export default ZkMarketplace;
