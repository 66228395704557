import React, { useState } from 'react';

const qorbiServices = require('../context/QorbiService');

const SendEmberPopup = ({ isOpen, closePopup, emberBalance, user, onSuccess }) => {

    const [amount, setAmount] = useState('');
    const [error, setError] = useState('');

    const handleInputChange = (e) => {
        const value = e.target.value;
        if (/^\d+$/.test(value) || value === '') {
            setAmount(value);
            setError('');
        }
    };

    const handleSubmit = async () => {
        const parsedAmount = parseInt(amount, 10);

        if (parsedAmount <= 0) {
            setError('Amount must be greater than zero.');
            return;
        }

        if (parsedAmount > emberBalance) {
            setError('Amount exceeds your balance.');
            return;
        }

        try {
            qorbiServices.sendEmberForPool(user, parsedAmount)
            onSuccess(parsedAmount);
            closePopup();
        } catch (err) {
            setError('An error occurred while sending Ember to the pool.');
        }
    };

    return isOpen ? (
        <div className="popup-overlay">
            <div className="popup-content">
                <h2>Send Ember to Pool</h2>
                <input
                    type="number"
                    value={amount}
                    onChange={handleInputChange}
                    placeholder={`Max: ${emberBalance}`}
                    max={emberBalance}
                />
                {error && <p className="error-message">{error}</p>}
                <div className="popup-actions">
                    <button onClick={closePopup}>Cancel</button>
                    <button onClick={handleSubmit}>Send</button>
                </div>
            </div>
        </div>
    ) : null;
};

export default SendEmberPopup;
