import React, { useState, useContext } from 'react';
import { ImmutableERC1155Abi } from '@imtbl/contracts'
import detectEthereumProvider from '@metamask/detect-provider'
import { ethers } from 'ethers';
import { Context } from "../context/WalletContext";
import { passportInstance } from "../context/PassportService.js";
import nftAbi from '../context/ABI/nft.json';
import { Web3Provider } from '@ethersproject/providers';

const Testing = () => {
    const { state } = useContext(Context);

    const tokenAddress = '0xeab326d39cb85a3d6b1590e0342f9851aec6151d'
    const recipient = '0xf798562a8264D53d4256780f6888DACC7b34A787'
    const amount = '156'

    const [qorbiBalance, setQorbiBalance] = useState(0);

    const getTokenBalance = async () => {
        try {
            const providerMetamask = await detectEthereumProvider();
            const provider = await new ethers.providers.Web3Provider(providerMetamask);
            const network = await provider.getNetwork();
            if (network.chainId !== 80002) {
                throw new Error('Please switch your wallet to the Amoy network.');
            }
            const erc20Abi = [
                "function balanceOf(address owner) view returns (uint256)",
            ];
            const contract = new ethers.Contract(tokenAddress, erc20Abi, provider);
            const balance = await contract.balanceOf(state?.user?.data);
            setQorbiBalance(ethers.utils.formatUnits(balance, 18));
        } catch (error) {
            console.error(error.message);
        }
    };

    const transferTokens = async () => {
        try {
            if (!tokenAddress || !recipient || !amount) throw new Error('All fields are required!');
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();
            const erc20Abi = [
                "function transfer(address to, uint256 amount) returns (bool)",
            ];
            const contract = new ethers.Contract(tokenAddress, erc20Abi, signer);
            const tx = await contract.transfer(recipient, ethers.utils.parseUnits(amount, 18));
            console.log('Transaction submitted...');
            await tx.wait();
            console.log(tx);
            console.log('Transaction successful!');
        } catch (error) {
            console.log(error.message);
        }
    };

    const transferPassport = async () => {
        const provider = passportInstance.connectEvm();
        const accounts = await provider.request({ method: 'eth_requestAccounts' });
        const ethersProvider = new Web3Provider(provider);
        const signer = ethersProvider.getSigner();
        const collection = '0xB1dBf0fC711F268B3a3755f8171a9269d6d105FD';
        const wallet = '0x3b0a372bd8abd8d31674023c11a0b55b8b4c9734';
        // const contract = new ethers.Contract(
        //     '0xB1dBf0fC711F268B3a3755f8171a9269d6d105FD',
        //     [
        //         'function safeTransferFrom(address from, address to, uint256 tokenId)',
        //     ],
        //     signer
        // );
        const contract = new ethers.Contract(
            collection,
            ImmutableERC1155Abi,
            signer,
        )
        const gasOverrides = {
            maxPriorityFeePerGas: ethers.utils.parseUnits('10', 'gwei'),
            maxFeePerGas: ethers.utils.parseUnits('15', 'gwei'),
            gasLimit: 200000,
        };
        const tx = await contract.safeTransferFrom(accounts[0], '0xF3F1e9D508D4a8f3620050E51aae8597b9dcaf48', '2', gasOverrides);
        const responseTransfer = await tx.wait();
        console.log(responseTransfer);
    }

    const transferAsset = async (fromWalletAddress, toWalletAddress, tokenId) => {
        const provider = await detectEthereumProvider();
        if (!provider) {
            console.error('Ethereum provider not found');
            return;
        }
        try {
            await provider.request({ method: 'eth_requestAccounts' });
            const library = new Web3Provider(provider)
            const signer = library.getSigner();
            const contractAddress = '0x7a6d3FB14A2eEFD8173584a96ABD2fAFA08e396a';
            const contract = new ethers.Contract(contractAddress, nftAbi.abi, signer);
            // Perform the transfer
            const tx = await contract.transferFrom('0xf3f1e9d508d4a8f3620050e51aae8597b9dcaf48', '0x4439fdd338Fd656bDf18d56b2967D022f286E22b', '263', { gasPrice: 0, gasLimit: 60462 });
            console.log(tx);
            // Wait for the transaction receipt
            const receipt = await library.waitForTransaction(tx.hash);
            console.log(receipt);
            
            return { receipt };
        } catch (error) {
            return { error };
        }
    };

    return (
        <div style={{ marginTop: '100px', justifyItems: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
            <div style={{ color: '#fff', fontSize: '32px' }}>This is the Testing screen</div>
            <div style={{ color: '#fff', fontSize: '24px' }}>Current Wallet: {state?.user?.data.toUpperCase()}</div>
            <button onClick={getTokenBalance}>Get Qorbi Balance</button>
            <div style={{ color: '#fff', fontSize: '24px' }}>Qorbi Balance: {qorbiBalance}</div>
            <div>-----------------------------------------------------------------------------------------------------------------------</div>
            <h3>Transfer Tokens</h3>
            <div style={{ color: '#fff', fontSize: '24px' }}>Wallet Target: {recipient.toUpperCase()}</div>
            <div style={{ color: '#fff', fontSize: '24px' }}>Amount: {amount}</div>
            <button onClick={transferTokens}>Transfer</button>
            <div>-----------------------------------------------------------------------------------------------------------------------</div>
            <h3>Transfer Passport</h3>
            <div style={{ color: '#fff', fontSize: '24px' }}>Wallet Target: {'0xF3F1e9D508D4a8f3620050E51aae8597b9dcaf48'.toUpperCase()}</div>
            <button onClick={transferAsset}>Transfer</button>
        </div>
    );
};

export default Testing;