import React from "react";
import logoElixir from '../assets/img/logoElixir.png'
import logoHyperPlay from '../assets/img/HyperPlayCustomBadge.png'

const Download = () => {

  return (
    <div style={{ background: "black" }}>
      <section class="hero-download">
        <h1>Discover the Future of Gaming</h1>
        <p>Play your favorite FPS gameand earn real rewards in the Qorbi World Play & Earb Airdrop. Are you ready to take your gaming to the next level?</p>

        <div class="buttons-container-download">
          <button class="button-download" onClick={() => window.open("https://store.hyperplay.xyz/game/onetap", "_blank")}>
            <img src={logoHyperPlay} alt="elixir" />
          </button>
          <button class="button-download" onClick={() => window.open("https://launcher.elixir.app/browse/one-tap", "_blank")}>
            <img src={logoElixir} alt="elixir" style={{ maxWidth: '70%', maxHeight: '70%' }} />
          </button>
          <button onClick={() => window.open("https://medium.com/@qorbiworld/airdrop-alert-get-ready-to-play-rank-and-earn-3d83231b3dce", "_blank")} class="button-download greenBtn">
            Read Full Details
          </button>
        </div>
        <img className="hero-download-img" src="https://digital.qorbiworld.com/12ONETAP_PLAY_AND_EARN_AIRDROP2.png" alt="I will forget I saw what was here before this text..." />
      </section>
      <section class="features-download">
        <div class="feature-card-download">
          <h3>Play Airdrop</h3>
          <p>A pure earning airdrop with no purchase needed. Earn with every match</p>
        </div>
        <div class="feature-card-download">
          <h3>To Qualify for Earning</h3>
          <p>Get at least 1 kill and complete a match. Huge max limits for rewards await!</p>
        </div>
        <div class="feature-card-download">
          <h3>Weekly In-Game Items Airdrops</h3>
          <p>Tap participants receive exclusive in-game items every week as part of our airdrop rewards program.</p>
        </div>
      </section>
      <section class="hero-download">
        <div class="buttons-container-download">
          <a href="https://discord.com/invite/tvHSEAubzn" class="button-download greenBtn">Join the Community</a>
          <a href="https://quest.playmana.gg/quests" class="button-download greenBtn">Start Questing Today</a>
          <a href="/market" class="button-download greenBtn">Shop our Earning Product</a>
        </div>
      </section>
    </div>
  );
};

export default Download;
