import axios from 'axios';

// const baseUrl = "https://api.qorbiworld.com"
// const devUrl = "https://dev-api.qorbiworld.com"
// const baseUrl = "http://localhost:4001"

const location = window.location
const baseUrl = location.host === 'dev.qorbaby.com' ? "https://dev-api.qorbiworld.com" : "https://api.qorbiworld.com";

axios.interceptors.request.use(
    (config) => {
        const excludedEndpoints = [
            `${baseUrl}/migrate/openloot-zkevm`
        ];
        const shouldExclude = excludedEndpoints.some((endpoint) =>
            config.url.includes(endpoint)
        );

        if (!shouldExclude) {
            const token = localStorage.getItem('qorbiSession');
            if (token) {
                config.headers['Authorization'] = token;
            }
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(
    (response) => {
        if (response.config.url.startsWith(baseUrl)) {
            const authorizationHeader = response.headers['authorization'];
            if (authorizationHeader) {
                localStorage.setItem('qorbiSession', authorizationHeader);
            }
        }
        return response;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export const availableOpenlootMigration = async () => {
    const res = await axios.get(`${baseUrl}/migrate/available-openloot-zkevm`)
    return res.data;
};

export const createQorbiUsername = async (username) => {
    await axios.patch(`${baseUrl}/authentication/set-username`, { username });
};

export const addQorbToPool = async (qorbData) => {
    const response = await axios.post(`${baseUrl}/stake/add-qorb-pool`, qorbData)
    return response.data;
};

export const openMysteryBox = async (tokenId) => {
    const response = await axios.post(`${baseUrl}/zkevm/open-mystery-box`, { tokenId })
    return response.data;
};

export const joinPool = async (referral) => {
    try {
        await axios.patch(`${baseUrl}/user/enable-playing-pool`, { referral });
        return true
    } catch (error) {
        console.error(error);
    }
};

export const migrateAssetsFromOpenLoot = async (authToken, wallet) => {
    try {
        const response = await axios.post(
            `${baseUrl}/migrate/openloot-zkevm`,
            {
                walletAddress: wallet,
            },
            {
                headers: {
                    Authorization: `${authToken}`,
                },
            }
        );

        return response.data;
    } catch (error) {
        console.error("Error migrating assets:", error);
        throw error;
    }
};

export const getOpenLootUserData = async (code, userId, redirectUri) => {
    const response = await axios.patch(`${baseUrl}/open-loot/user-data-by-token`, {
        code,
        redirectUri,
        userId,
    }, {
        headers: {
            'Content-Type': 'application/json',
            'accept': '*/*',
        }
    });
    return response.data;
};

export const updateOpenLootUserData = async (code, userId, redirectUri) => {
    const response = await axios.post(`${baseUrl}/open-loot/get-user-data`, {
        code,
        redirectUri,
        userId,
    }, {
        headers: {
            'Content-Type': 'application/json',
            'accept': '*/*',
        }
    });
    return response.data;
};

export const getQorbiPrice = async (asset, userId) => {
    const response = await axios.post(`${baseUrl}/v1/nft/qorbi-market/set-item`, { asset, userId });
    return response.data;
};

export const mintQorbiMarket = async (walletAddress) => {
    const response = await axios.post(`${baseUrl}/v1/nft/qorbi-market/mint/${walletAddress}`);
    return response.data;
};

export const trackMigration = async (id) => {
    try {
        const response = await axios.get(`${baseUrl}/migrate/status/${id}`);
        return response.data;
    } catch (error) {
        console.error("Error tracking migration status:", error);
        throw error;
    }
};

export const qorbiBalance = async () => {
    try {
        const response = await axios.get(`${baseUrl}/qorbi-pool/balance`);
        return response.data;
    } catch (error) {
        console.error("Error getting balance:", error);
        throw error;
    }
};

export const emberPoolBalance = async () => {
    try {
        const response = await axios.get(`${baseUrl}/user/ember/pool-balance`);
        return response.data.emberBalance;
    } catch (error) {
        console.error("Error getting ember pool balance:", error);
        throw error;
    }
};

export const trackMysteryBox = async (id) => {
    try {
        const response = await axios.get(`${baseUrl}/zkevm/status-open-mystery-box/${id}`);
        return response.data;
    } catch (error) {
        console.error("Error tracking Mystery Box status:", error);
        throw error;
    }
};

export const getActivePass = async () => {
    try {
        const response = await axios.get(`${baseUrl}/stake/get-x-stake-active`);
        return response.data;
    } catch (error) {
        console.error("Error getting QorX data:", error);
        throw error;
    }
};

export const checkAndSpend = async (email, otpCode) => {
    try {
        await axios.patch(`${baseUrl}/sphere-one/check-and-spend/${email}/${otpCode}`);
        return true;
    } catch (error) {
        console.error("Error whitelisting:", error);
        throw error;
    }
};

export const verifyEmail = async (email) => {
    try {
        await axios.patch(`${baseUrl}/authentication/verify`, { email });
        return true;
    } catch (error) {
        console.error("Error checking Email:", error);
        throw error;
    }
};

export const createQorbiEmail = async (email, otp) => {
    try {
        const response = await axios.patch(`${baseUrl}/authentication/set-email`, { email, otp });
        console.log(response);
    } catch (error) {
        console.error("Error checking Email:", error);
        throw error;
    }
};

export const withdrawQorb = async (amount, receiver) => {
    try {
        await axios.patch(`${baseUrl}/stake/withdrawal-qorb`, { amount, receiver });
    } catch (error) {
        console.error("Error checking Email:", error);
        throw error;
    }
};

export const withdrawQorx = async (amount, receiver, tokenId, chain) => {
    try {
        await axios.patch(`${baseUrl}/stake/withdrawal-qorx-pass`, { amount, receiver, tokenId, chain });
    } catch (error) {
        console.error("Error checking QORX:", error);
        throw error;
    }
};

export const withdrawMana = async (receiver, otp) => {
    try {
        await axios.patch(`${baseUrl}/stake/withdrawal-mana`, { receiver, otp });
    } catch (error) {
        console.error("Error checking MANA:", error);
        throw error;
    }
};

export const requestOtp = async () => {
    try {
        const response = await axios.post(`${baseUrl}/sphere-one/request-otp`);
        return response.data.message === "OTP sent successfully" && true
    } catch (error) {
        console.error("Error requesting OTP:", error);
        throw error;
    }
};

export const authPassport = async (code) => {
    try {
        const response = await axios.post(`${baseUrl}/authentication/passport-imx`, { accessToken: code });
        localStorage.setItem('qorbiSession', response.headers['authorization'])
        return response?.data.setUsername;
    } catch (error) {
        console.error("Error Authenticating Passport:", error);
        throw error;
    }
};

export const sendEmberForPool = async (user, amount) => {
    try {
        const response = await axios.post(`${baseUrl}/user/ember/send-to-pool`, {
            user,
            transaction: 'MrPoshoFrito',
            amount,
        });
        if (response.data.message === 'Ember successfully transferred to pool') {
            return true;
        } else {
            return false;
        }
    } catch (error) {
        console.error("Error Authenticating Passport:", error);
        throw error;
    }
};

export const verifySignature = async (walletAddress, message, signature) => {
    try {
        const response = await axios.post(`${baseUrl}/authentication/verify-signature-wallet`, { walletAddress, message, signature });
        localStorage.setItem('qorbiSession', response.headers['authorization'])
        return response?.data
    } catch (error) {
        console.error("Error Authenticating Metamask:", error);
        throw error;
    }
};

export const authUnity = async (id) => {
    try {
        const response = await axios.get(`${baseUrl}/authentication/generate-unity-access/${id}`);
        console.log(response.status);
    } catch (error) {
        console.error("Error Authenticating Unity:", error);
        throw error;
    }
};

export const authSessionToken = async (id) => {
    try {
        const response = await axios.get(`${baseUrl}/authentication/get-session-token/${id}`);
        console.log(response.data);
    } catch (error) {
        console.error("Error Authenticating Unity:", error);
        throw error;
    }
};

export const userByToken = async () => {
    try {
        const response = await axios.get(`${baseUrl}/user`);
        return response.data
    } catch (error) {
        console.error("Error Getting User Information:", error);
        throw error;
    }
};

export const generateNonce = async () => {
    try {
        const response = await axios.get(`${baseUrl}/authentication/generate-nonce`);
        return response.data.nonce
    } catch (error) {
        console.error("Error Getting Nonce:", error);
        throw error;
    }
};

export const getOpenLootAssets = async (id) => {
    try {
        const response = await axios.get(`${baseUrl}/open-loot/user-nfts/${id}`);
        const assets = response.data;

        for (let asset of assets) {
            if (asset.metadata?.name === "Qorb Premier Badge") {
                try {
                    const checkMintResponse = await axios.get(
                        `${baseUrl}/v1/check-badge-able-to-mint?tokenId=${asset.id}`
                    );
                    asset.isAble = checkMintResponse.data.isAble === true;
                } catch (checkError) {
                    console.error(`Error checking if badge can mint for asset ID ${asset.id}:`, checkError);
                    asset.isAble = false;
                }
            } else {
                asset.isAble = false;
            }
        }

        return assets;
    } catch (error) {
        console.error("Error fetching Open Loot assets:", error);
        throw error;
    }
};