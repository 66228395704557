
import { ERC721TokenType, Link } from "@imtbl/imx-sdk";
import { linkAddress, Toast } from "../utils/Global";
import { useNavigate } from 'react-router-dom';
import { Context } from "../context/WalletContext";
import { Button } from "../components/Button";
import React, { useState, useContext, useEffect } from 'react';
import Popup from "../components/Popup";
import ForgeLoader from "../assets/svg/ForgeLoader";
import defaultImage from "../assets/img/PlaceholderNft.png";
const immutableService = require('../context/ImmutableService');
// const sphereOneService = require('../context/SphereOneService')

const EggTrade = () => {
    const link = new Link(linkAddress);
    const navigate = useNavigate();

    const { state } = useContext(Context);

    const [carouselImages, setCarouselImages] = useState([defaultImage]);
    const [nftItems, setNftItems] = useState([])
    const [showPopup, setShowPopup] = useState(false);
    const [selectedNFTs, setSelectedNFTs] = useState([]);
    const [showLoader, setShowLoader] = useState(false)

    const handleNFTClick = (nft) => {
        if (selectedNFTs.length < 1 && !selectedNFTs.some(selected => selected.token_id === nft.token_id)) {
            setSelectedNFTs([...selectedNFTs, nft]);
            setCarouselImages((prevImages) => {
                const newImages = [...prevImages];
                newImages[selectedNFTs.length] = nft.image_url;
                return newImages;
            });
        }
    };

    const handleReset = () => {
        setSelectedNFTs([]);
        setCarouselImages([defaultImage]);
    };

    const handleBurnSkins = async () => {
        // const imxWallet = {
        //     privateKey: '-',
        //     starkPrivateKey: '-'
        // }
        // sphereOneService.transferImxNft(
        //     '0x8a2315e441786e10ff1962387407628108b9ba33',
        //     '3597',
        //     '0xF3F1e9D508D4a8f3620050E51aae8597b9dcaf48',
        //     imxWallet
        // ).then((response) => console.log("NFT transferred:", response))
        //     .catch((error) => console.error("Error transferring NFT:", error));
        setShowPopup(true)
    };

    const sendSkinFromForge = async () => {
        const maxRetries = 6;

        const tradeSkins = async (retryCount = 0) => {
            try {
                const token = localStorage.getItem('qorbiSession');
                const tradeRequest = await fetch('https://api.qorbiworld.com/v1/nft/trade-3-eggs', {
                    method: 'POST',
                    headers: {
                        Authorization: token,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        walletAddress: state?.userWallet?.string,
                    }),
                });

                if (!tradeRequest.ok) {
                    throw new Error('Failed to complete trade');
                }

                const tradeResponse = await tradeRequest.json();
                const followId = tradeResponse.followId;
                const checkStatus = async (currentRetry = 0) => {
                    let status = '';

                    while (status !== 'completed' && status !== 'failed') {
                        if (currentRetry >= maxRetries) {
                            return tradeSkins(retryCount + 1);
                        }
                        await new Promise(resolve => setTimeout(resolve, 5000));
                        const followRequest = await fetch(`https://api.qorbiworld.com/v1/nft/trade-3-eggs/${followId}`,{
                            headers: {
                                Authorization: token
                            },
                        });
                        if (!followRequest.ok) {
                            throw new Error('Failed to fetch follow status');
                        }
                        const followResponse = await followRequest.json();
                        status = followResponse.data.status;
                        currentRetry++;
                    }
                    if (status === 'completed') {
                        setTimeout(() => {
                            navigate('/my-assets');
                        }, 5000);
                        setShowLoader(false);
                        Toast.set({
                            show: true,
                            desc: 'Congratulations! Your Eggs have been successfully traded for a lootbox.',
                            type: 'success',
                        });
                    } else {
                        setTimeout(() => {
                            navigate('/my-assets');
                        }, 5000);
                        setShowLoader(false);
                        Toast.set({
                            show: true,
                            desc: 'Oops! Trade process failed. Please contact us using discord. CODE: 002',
                            type: 'error',
                        });
                    }
                };
                await checkStatus();
            } catch (error) {
                console.error('Error:', error);
                setShowLoader(false);
                setTimeout(() => {
                    navigate('/my-assets');
                }, 5000);
                Toast.set({
                    show: true,
                    desc: 'Something went wrong. Please contact us using discord. CODE: 003',
                    type: 'error',
                });
            }
        };

        await tradeSkins();
    };

    const verify = async () => {
        setShowPopup(false);
        setShowLoader(true)
        const array = [];
        const nfts = [];
        selectedNFTs.forEach((element) => {
            array.push(element.token_id);
            nfts.push({
                type: ERC721TokenType.ERC721,
                tokenId: element.token_id,
                tokenAddress: element.token_address,
                toAddress: "0x315fc5811775Ab7cb1d8F0F4F474E711814Cc53a",
            });
        });
        await link.setup({
            providerPreference: "none",
        });
        let response = await link.batchNftTransfer(nfts);
        if (response.result.length > 0) {
            sendSkinFromForge()
        }
    };
    useEffect(() => {
        const fetchEggs = async (walletAddress) => {
            await immutableService.getAssetsFromCollections(walletAddress, eggCollection, updateAssets);
        };
        const updateAssets = (newAssets) => {
            setNftItems((prevItems) => {
                const eggAssets = newAssets.filter(item => item.metadata?.class === "Pet Egg");;
                return [...prevItems, ...eggAssets];
            });
        };
        const user = state?.userWallet?.string;
        const eggCollection = ['0xeea2fe42cc1774a802edfc7a08a35c361827befc'];
        if (user !== undefined && user !== null) {
            fetchEggs(user).then();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="bundle-container">
            <div className="bundle-top-segment">
                <div className="bundle-left-part">
                    <h1>Let's Forge a Lootbox Omelet!</h1>
                    <p>Let's get rid of the extra Pet Eggs, and trade them to get a new Lootbox.</p>
                    <p className="conditional-text">Crush your existing items to unlock a new lootbox and increase your skin collection!</p>
                    <div className="button-container">
                        {selectedNFTs.length > 0 && (
                            <button className="carousel-button-reset" onClick={handleReset}>
                                Reset
                            </button>
                        )}
                        {selectedNFTs.length === 1 && (
                            <button className="carousel-button-burn" onClick={handleBurnSkins}>
                                Trade Eggs
                            </button>
                        )}
                    </div>
                </div>
            </div>
            {showLoader && (
                <div className="loader-layer-1">
                    <div className="loader-layer-2">
                        <ForgeLoader />
                        <h2 style={{ margin: '10px 0' }}>Cooking the eggs</h2>
                        <h2 style={{ margin: '10px 0' }}>Using the ashes to forge a box?</h2>
                    </div>
                </div>
            )}
            {showPopup ? (
                <Popup
                    onClose={() => setShowPopup(false)}
                    title="Confirmation required"
                    content={
                        <>
                            <p>
                                Are you sure you want to send your eggs to the kitchen? MrP might eat them.
                            </p>
                            <Button label="Yes, Please" onClick={() => verify()} />
                            <span style={{ width: 10, display: "inline-block" }} />
                            <Button transparent label="No" onClick={() => setShowPopup(false)} />
                        </>
                    }
                />
            ) : null}
            <div className="bundle-bottom-segment">
                <div className="bundle-static-images">
                    {carouselImages.map((image, index) => (
                        <div className="bundle-static-image-item" key={index}>
                            <img src={image} alt={`Static ${index + 1}`} />
                        </div>
                    ))}
                </div>
            </div>
            <div className="section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 right-side">
                            <h2>Your Assets</h2>
                            <div className="row">
                                {nftItems?.length > 0 ? (
                                    nftItems.map((item) => (
                                        <div
                                            className={`col-md-3 mb-3`}
                                            key={item.token_id}
                                            onClick={() => handleNFTClick(item)}
                                        >
                                            <div
                                                className="bg"
                                                style={{
                                                    display: 'flex', justifyContent: 'center',
                                                    overflow: 'hidden'
                                                }}
                                            >
                                                <img className={`tokenImage ${selectedNFTs.some(selected => selected.token_id === item.token_id) ? 'selected-nft' : ''}`}
                                                    src={item.image_url}
                                                    style={{ width: "50vw" }}
                                                    alt={item.name}
                                                />
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <h2 className="text-center">
                                        There are no assets for Qorbi World in your wallet
                                    </h2>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EggTrade;
