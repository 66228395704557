import React, { useState } from 'react';

const InputWallet = ({ onSuccess }) => {
    const [walletAddress, setWalletAddress] = useState('');
    const [confirmationMessage, setConfirmationMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const isValidWalletAddress = (address) => {
        const walletRegex = /^0x[a-fA-F0-9]{40}$/;
        return walletRegex.test(address);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (isValidWalletAddress(walletAddress)) {
            setConfirmationMessage(`The QORBI will go to: ${walletAddress}`);
            setErrorMessage('');
            onSuccess(walletAddress);
        } else {
            setErrorMessage('Invalid wallet address. Please ensure it starts with "0x" and is 42 characters long.');
            setConfirmationMessage('');
        }
    };

    return (
        <div className="wallet-input-container">
            <h2 className="wallet-input-title">Enter Your Polygon Wallet Address</h2>
            <form onSubmit={handleSubmit} className="wallet-input-form">
                <input
                    type="text"
                    className="wallet-input-field"
                    placeholder="0x..."
                    value={walletAddress}
                    onChange={(e) => setWalletAddress(e.target.value)}
                />
                <button type="submit" className="wallet-submit-button">
                    Submit
                </button>
            </form>
            {errorMessage && <p className="error-message">{errorMessage}</p>}
            {confirmationMessage && <p className="confirmation-message">{confirmationMessage}</p>}
        </div>
    );
};

export default InputWallet;
