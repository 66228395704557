import * as React from "react"

const Filter = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={props.width ? props.width : 34.6} height={props.height ? props.height : 34.6} {...props}>
    <g data-name="Grupo 16509">
      <g data-name="Trazado 68649" fill="rgba(255,255,255,0.02)">
        <path d="M17.3 0A17.3 17.3 0 1 1 0 17.3 17.3 17.3 0 0 1 17.3 0Z" />
        <path
          d="M17.3.3C12.76.3 8.49 2.068 5.28 5.28A16.889 16.889 0 0 0 .3 17.3c0 4.54 1.768 8.81 4.98 12.02 3.21 3.212 7.48 4.98 12.02 4.98 4.54 0 8.81-1.768 12.02-4.98 3.212-3.21 4.98-7.48 4.98-12.02 0-4.54-1.768-8.81-4.98-12.02A16.889 16.889 0 0 0 17.3.3m0-.3c9.555 0 17.3 7.745 17.3 17.3s-7.745 17.3-17.3 17.3S0 26.855 0 17.3 7.745 0 17.3 0Z"
          fill="rgba(255,255,255,0.08)"
        />
      </g>
      <path
        d="M16.011 28.203a.4.4 0 0 1-.209-.058.411.411 0 0 1-.2-.353V19.94l-5.542-9.32a.41.41 0 0 1 .352-.619h16.014a.41.41 0 0 1 .353.619l-5.543 9.32v5.227a.407.407 0 0 1-.214.359l-4.814 2.625a.4.4 0 0 1-.2.052Zm-4.88-17.382 5.232 8.8a.41.41 0 0 1 .057.209v7.274l3.992-2.178v-5.1a.407.407 0 0 1 .058-.209l5.232-8.8Z"
        fill="#f2f2f2"
        opacity={0.25}
      />
    </g>
  </svg>
)

export default Filter
